// Bootstrap defaults that were not found (!?)
.mr-1 {
  margin-right: $spacer * .25;
}
.mr-2 {
  margin-right: $spacer * .5;
}
.mr-2 {
  margin-right: $spacer;
}

.bg-bo {
  background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);
}

.btn-primary {
  color: white;
}

.table thead th {
  font-size: small;
}
th {
  color: $gray-800;
}

.scrollable-list-group {
  max-height: 300px;
  overflow-y: auto;
}
.list-group-item:hover {
  background-color: $secondary;
  color: white;
  cursor: pointer;
}
.inportfolio {
  color: $gray-500;
}
.list-group-item.inportfolio:hover {
  background-color: inherit;
  color: $gray-500;
  cursor: auto;
}

.avatar {
  width: 40px;   /* adjust as needed */
  height: 40px;  /* adjust as needed */
  border-radius: 50%;
  background: $primary;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;   /* adjust as needed */
}

.toaster {
  margin-top: 60px;
  right: 0;
  top: 0;
}

.demand-surges-table > table > td {
  font-size: 90%;
}

.coverage-widget > .card-body > .text-high-emphasis {
  font-size: $h6-font-size !important;
}
.coverage-widget > .card-body > div:nth-child(2) {
  font-size: $small-font-size;
}
.coverage-widget > .card-body > .progress {
  margin-top: $spacer * 1.5 !important;
}

a,
.page-link {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.sidebar-overlaid {
  box-shadow: rgb(50 50 93 / 2%) 0 2px 5px -1px, rgb(0 0 0 / 5%) 0 1px 3px -1px, 0 5px 6px -3px rgb(0 0 0 / 25%);
}

.card-header {
  border-bottom: 1px solid var(--cui-card-border-color);
}

.header {
  border: 0;
}

.nav-link:hover {
  color: $primary;
}

.sidebar-nav {
  margin: .75rem;
  .nav-link {
    @include border-radius($border-radius);
  }
  .nav-group.show .nav-group-toggle:hover {
    color: $high-emphasis-inverse;
  }
}

.sidebar-brand {
  justify-content: space-between;
  padding: 0 $sidebar-nav-link-padding-x;
}

.sidebar-toggler {
  flex: 0 0 2rem;
  background-color: transparent;

  &::before {
    width: 2rem;
  }

  &:hover {
    background-color: transparent;
  }
}

.sidebar-narrow-unfoldable:not(:hover) .sidebar-toggler {
  display: none;
}
